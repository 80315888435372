import React, { FC } from 'react';
import { connectStateResults } from 'react-instantsearch-dom';

import { Container } from 'layout';
import PopularSearchTerms from 'components/PopularSearchTerms';

import { GlobalResultsHandlerProps } from './models';

import './GlobalResultsHandler.scss';

const Results = ({
  searchResults,
  children,
  noResultsText,
  popularSearchTermsTitle,
  popularSearchTermsButtonText,
  popularSearchTerms,
}) => (
  <>
    {searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div className="search-page__no-results">
        <Container>
          <p className="search-page__no-results-text standfirst">{noResultsText}</p>
          <PopularSearchTerms
            popularSearchTermsTitle={popularSearchTermsTitle}
            popularSearchTermsButtonText={popularSearchTermsButtonText}
            popularSearchTerms={popularSearchTerms}
          />
        </Container>
      </div>
    )}
  </>
);

const GlobalResultsHandler: FC<GlobalResultsHandlerProps> = connectStateResults(Results);

export default GlobalResultsHandler;
