import React, { FC } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { Link } from '@reach/router';

import { Col, DangerouslySetInnerHtml, Row } from 'layout';
import Breadcrumbs from 'components/Breadcrumbs';
import { formatDisplayDate } from 'utils/dateHandler';

import Arrow from '../icons/arrow-right.svg';
import { GlobalSearchHitsProps } from './models';

import './GlobalSearchHits.scss';

const SearchPageHits: FC<GlobalSearchHitsProps> = ({
  hits,
  hasMore,
  refineNext,
  lang,
  goToPageButtonText,
  loadMoreButtonText,
}) => (
  <>
    <Row element="ul" className="search-page__list">
      {hits?.map((hit) => (
        <Col element="li" xl="10" key={hit.objectID} className="col--no-gutters search-page__item">
          <div className="search-page__item-content">
            <Breadcrumbs level={hit.level} pathnameUrl={hit.url} />
            {hit.articleType || hit.creationDate ? (
              <div className="search-page__item-tag-date">
                {hit.articleType ? (
                  <span className="search-page__item-tag tag">{hit.articleType.name}</span>
                ) : null}
                {hit.creationDate ? (
                  <span className="search-page__item-date tag">
                    {formatDisplayDate(hit.creationDate, lang)}
                  </span>
                ) : null}
              </div>
            ) : null}
            <h2 className="search-page__item-title">
              {hit.parentTitle || hit.seoMetaTitle || hit.title}
            </h2>
            {hit.seoMetaDescription || hit.description ? (
              <DangerouslySetInnerHtml
                className="search-page__item-snippet"
                html={hit.seoMetaDescription || hit._snippetResult.description.value}
              />
            ) : null}
            <Link to={hit.parentUrl || hit.url} className="search-page__item-link link link--pink">
              {goToPageButtonText}
              <Arrow className="search-page__item-icon" aria-hidden="true" focusable="false" />
            </Link>
          </div>
        </Col>
      ))}
    </Row>
    {hasMore ? (
      <button
        type="button"
        className="search-page__btn btn btn--primary btn--pink"
        onClick={refineNext}
        disabled={!hasMore}
      >
        {loadMoreButtonText}
      </button>
    ) : null}
  </>
);

const GlobalSearchHits = connectInfiniteHits(SearchPageHits);

export default GlobalSearchHits;
