import React, { FC } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { graphql } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';

import { Col, Container, Row } from 'layout';
import Layout from 'layout/Layout';
import GlobalResultsHandler from 'common/GlobalResultsHandler';
import GlobalSearchHits from 'common/GlobalSearchHits';
import GlobalSearchStats from 'common/GlobalSearchStats';
import PageSchema from 'common/PageSchema';
import SearchInput from 'common/SearchInput';
import Seo from 'common/Seo';

import { SearchPageTypes } from './models';

import './SearchPage.scss';

const SearchPage: FC<SearchPageTypes.SearchPageType> = ({ data: { searchPopup, searchPage } }) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    searchPage.seo;
  const { title, lang, url } = searchPage;
  const {
    searchPlaceholder,
    searchButtonText,
    goToPageButtonText,
    loadMoreButtonText,
    popularSearchTermsTitle,
    popularSearchTermsButtonText,
    popularSearchTerms,
  } = searchPopup;

  const client = algoliasearch(
    `${process.env.GATSBY_ALGOLIA_APP_ID}`,
    `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
  );

  return (
    <Layout headerTransparent className="search-page">
      <Seo
        {...{
          seo: searchPage.seo,
          openGraph: searchPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <InstantSearch
        searchClient={client}
        indexName={process.env.GATSBY_ALGOLIA_SEARCH_QUERY_INDEX}
      >
        <Configure hitsPerPage="12" filters={`lang: "${lang}"`} />
        <div className="search-page__banner">
          <Container>
            <Row>
              <Col xl="10" className="col--no-gutters">
                <h1 className="search-page__title h1">{title}</h1>
                <SearchInput
                  withAlgolia
                  searchPlaceholder={searchPlaceholder}
                  searchButtonText={searchButtonText}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <section className="search-page__content">
          <Container>
            <GlobalSearchStats followedText="results match this criteria" />
            <GlobalResultsHandler
              noResultsText="No results for search terms"
              popularSearchTermsTitle={popularSearchTermsTitle}
              popularSearchTermsButtonText={popularSearchTermsButtonText}
              popularSearchTerms={popularSearchTerms}
            >
              <GlobalSearchHits
                lang={lang}
                goToPageButtonText={goToPageButtonText}
                loadMoreButtonText={loadMoreButtonText}
              />
            </GlobalResultsHandler>
          </Container>
        </section>
      </InstantSearch>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    searchPopup {
      searchPlaceholder
      searchButtonText
      goToPageButtonText
      loadMoreButtonText
      popularSearchTermsTitle
      popularSearchTermsButtonText
      popularSearchTerms {
        term
      }
    }
    searchPage(url: { eq: $url }) {
      pageId
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
    }
  }
`;

export default SearchPage;
