import React, { FC } from 'react';
import { connectStats } from 'react-instantsearch-dom';

import { GlobalSearchStatsProps } from './models';

import './GlobalSearchStats.scss';

const Stats: FC<GlobalSearchStatsProps> = ({ nbHits, followedText }) => (
  <p className="search-page__stats">
    <span className="search-page__stats--bold">{`${nbHits.toLocaleString()} `}</span>

    {followedText}
  </p>
);

const GlobalSearchStats = connectStats(Stats);

export default GlobalSearchStats;
